import(/* webpackMode: "eager", webpackExports: ["SessionExtender"] */ "/app/.yarn/__virtual__/@edeeone-edee-registration-virtual-28947259a5/1/packages/edee-registration/disc/esm/components/SessionExtender.js");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/.yarn/__virtual__/@edeeone-juan-core-virtual-21908f8706/1/packages/juan-core/disc/esm/components/webVitals/WebVitals.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/.yarn/__virtual__/@edeeone-juan-core-virtual-21908f8706/1/packages/juan-core/disc/esm/i18n/i18nProvider/I18nDictionaryProviderClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutRootProviderClient"] */ "/app/.yarn/__virtual__/@edeeone-juan-core-virtual-21908f8706/1/packages/juan-core/disc/esm/page/LayoutRootProviderClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocationProvider"] */ "/app/.yarn/__virtual__/@edeeone-juan-core-virtual-21908f8706/1/packages/juan-core/disc/esm/routes/LocationProviderClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["ViewPageGoogleEventWrapper"] */ "/app/.yarn/__virtual__/@edeeone-shop-facebookbusiness-virtual-42048e0e11/1/packages/shop-facebookBusiness/disc/esm/googleTagManager/clientComponentWrappers.js");
;
import(/* webpackMode: "eager", webpackExports: ["JuanGoogleTagManager"] */ "/app/.yarn/__virtual__/@edeeone-shop-facebookbusiness-virtual-42048e0e11/1/packages/shop-facebookBusiness/disc/esm/googleTagManager/JuanGoogleTagManager.js");

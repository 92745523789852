import { getEventsConfig } from '@edeeone/juan-core/utils/analyticsEventsUtils';
import { nanoid } from 'nanoid';
import spawnGoogleEventOnApiServerAction from './spawnGoogleEventOnApi.serverAction';
import { updateUserDataInDataLayer, } from './updateUserDataInDataLayer';
import { GoogleTagsBE, GoogleTagsFE, parseQuantities } from './utils';
export async function emitGoogleEvent({ apiData, gtmData, locale, userData, }) {
    const analyticsEventsConfig = getEventsConfig(locale);
    if (typeof window === 'undefined') {
        return;
    }
    if (!analyticsEventsConfig?.gtmId) {
        return;
    }
    if (Array.isArray(window?.dataLayer)) {
        const falseContents = [];
        if (Array.isArray(apiData.customData?.contents)) {
            apiData.customData.contents = apiData.customData.contents.filter((content) => {
                if (content.productId == null ||
                    content.itemPrice == null ||
                    content.quantity == null) {
                    falseContents.push(content);
                    return false;
                }
                return true;
            });
        }
        if (falseContents.length) {
            if (Array.isArray(gtmData.ecommerce?.items)) {
                gtmData.ecommerce.items = gtmData.ecommerce.items.filter((item) => {
                    return (item.item_id != null && item.price != null && item.quantity != null);
                });
            }
            console.error('Emitting event, that has invalid value in contents:', apiData, 'Array of false contents:', falseContents);
        }
        if (userData) {
            await updateUserDataInDataLayer(locale, userData);
        }
        const userDataFromApi = await spawnGoogleEventOnApiServerAction(apiData, userData);
        const gtmDataWithUser = {
            ...gtmData,
            user_data: userDataFromApi,
        };
        if (analyticsEventsConfig?.logEventsWhenEmitting) {
            console.info('Emitting Google event:', gtmDataWithUser);
        }
        window.dataLayer.push(gtmDataWithUser);
    }
    else {
        // is not initialized yet, resolve in the next tick, it should be initialized then
        setTimeout(() => {
            emitGoogleEvent({
                apiData,
                gtmData,
                locale,
                userData,
            });
        }, 0);
    }
}
export async function emitAddToCartGoogleEvent({ cartId, currency, customDataLayerData, externalId, items, locale, }) {
    return Promise.all(items.map((it) => {
        const adjustment = it?.adjustment == null ? null : it?.adjustment;
        const eventId = `addToCart_${cartId}_${externalId}_${it.codeShort}_${it.quantity}_${adjustment}_${nanoid()}`;
        const eventName = 'add_to_cart';
        const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
        return emitGoogleEvent({
            locale,
            gtmData: {
                eventId,
                event: GoogleTagsFE[eventName],
                ecommerce: {
                    currency,
                    value: it.price,
                    items: [
                        {
                            item_category: it.categoryCodes?.join(', '),
                            item_id: it.codeShort,
                            item_name: it.name,
                            price: it.price,
                            quantity: roundedQuantity,
                            quantityBeforeRounding,
                        },
                    ],
                },
                ...(customDataLayerData ? { customDataLayerData } : {}),
            },
            apiData: {
                eventId,
                eventName: GoogleTagsBE[eventName],
                customData: {
                    contentIds: items
                        .map((it) => {
                        return it?.codeShort;
                    })
                        .filter((it) => {
                        return it;
                    }),
                    currency,
                    value: it.price,
                    contents: items.map((it) => {
                        return {
                            category: it?.categoryCodes?.join(', '),
                            productId: it?.codeShort,
                            title: it?.name,
                            itemPrice: it.price,
                            quantity: roundedQuantity,
                        };
                    }),
                },
            },
        });
    }));
}
export async function emitRemoveFromCartGoogleEvent({ cartId, currency, customDataLayerData, externalId, items, locale, }) {
    return Promise.all(items.map((it) => {
        const eventId = `removeFromCart_${cartId}_${externalId}_${it.codeShort}_${it.quantity}_${nanoid()}`;
        const eventName = 'remove_from_cart';
        const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
        return emitGoogleEvent({
            locale,
            gtmData: {
                eventId,
                event: GoogleTagsFE[eventName],
                ecommerce: {
                    currency,
                    value: it.price,
                    items: items.map((it) => {
                        return {
                            item_category: it.categoryCodes?.join(', '),
                            item_id: it.codeShort,
                            item_name: it.name,
                            price: it.price,
                            quantity: roundedQuantity,
                            quantityBeforeRounding,
                        };
                    }),
                },
                ...(customDataLayerData ? { customDataLayerData } : {}),
            },
            apiData: {
                eventId,
                eventName: GoogleTagsBE[eventName],
                customData: {
                    contentIds: items
                        .map((it) => {
                        return it?.codeShort;
                    })
                        .filter((it) => {
                        return it;
                    }),
                    currency,
                    value: it.price,
                    contents: items.map((it) => {
                        return {
                            category: it?.categoryCodes?.join(', '),
                            productId: it?.codeShort,
                            title: it?.name,
                            itemPrice: it.price,
                            quantity: roundedQuantity,
                        };
                    }),
                },
            },
        });
    }));
}
export async function emitViewCartGoogleEvent({ currency, customDataLayerData, items = [], locale, totalPrice = 0, }) {
    const eventId = `viewCart_${nanoid()}`;
    const eventName = 'view_cart';
    return emitGoogleEvent({
        locale,
        gtmData: {
            event: GoogleTagsFE[eventName],
            eventId,
            ecommerce: {
                currency,
                value: totalPrice,
                items: items.map((it) => {
                    const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
                    return {
                        item_category: it?.categoryCodes?.join(', '),
                        item_id: it?.codeShort,
                        item_name: it?.name,
                        price: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                        quantityBeforeRounding,
                    };
                }),
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: items
                    .map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                currency,
                value: totalPrice,
                contents: items.map((it) => {
                    const { roundedQuantity } = parseQuantities(it?.quantity);
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                    };
                }),
            },
        },
    });
}
export async function emitBeginCheckoutGoogleEvent({ currency, customDataLayerData, items = [], locale, totalPrice, }) {
    const eventId = `beginCheckout_${nanoid()}`;
    const eventName = 'begin_checkout';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                currency,
                value: totalPrice,
                items: items.map((it) => {
                    const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
                    return {
                        item_category: it?.categoryCodes?.join(', '),
                        item_id: it?.codeShort,
                        item_name: it?.name,
                        price: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                        quantityBeforeRounding,
                    };
                }),
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: items
                    .map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                currency,
                value: totalPrice,
                contents: items.map((it) => {
                    const { roundedQuantity } = parseQuantities(it?.quantity);
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                    };
                }),
            },
        },
    });
}
export async function emitAddShippingInfoGoogleEvent({ currency, customDataLayerData, items = [], locale, shippingCode, totalPrice, }) {
    const eventId = `addShippingInfo_${nanoid()}`;
    const eventName = 'add_shipping_info';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                shipping_tier: shippingCode,
                currency,
                value: totalPrice,
                items: items.map((it) => {
                    const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
                    return {
                        item_category: it?.categoryCodes?.join(', '),
                        item_id: it?.codeShort,
                        item_name: it?.name,
                        price: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                        quantityBeforeRounding,
                    };
                }),
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: items
                    .map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                currency,
                value: totalPrice,
                contents: items.map((it) => {
                    const { roundedQuantity } = parseQuantities(it?.quantity);
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                    };
                }),
            },
        },
    });
}
export async function emitAddPaymentInfoGoogleEvent({ cartId, currency, customDataLayerData, externalId, items = [], locale, paymentCode, paymentId, totalPrice, }) {
    const eventId = `addPaymentInfo_${cartId}_${externalId}_${paymentId}`;
    const eventName = 'add_payment_info';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                payment_type: paymentCode,
                currency,
                value: totalPrice,
                items: items.map((it) => {
                    const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
                    return {
                        item_category: it?.categoryCodes?.join(', '),
                        item_id: it?.codeShort,
                        item_name: it?.name,
                        price: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                        quantityBeforeRounding,
                    };
                }),
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: items
                    .map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                currency,
                value: totalPrice,
                contents: items.map((it) => {
                    const { roundedQuantity } = parseQuantities(it?.quantity);
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                    };
                }),
            },
        },
    });
}
export async function emitPurchaseGoogleEvent({ coupons, currency, customDataLayerData, externalId, items, locale, orderNumber, shipping, totalPrice, totalPriceOfItems, user, }) {
    if (!orderNumber) {
        return;
    }
    const eventId = `purchase_${externalId}_${orderNumber}`;
    const eventName = 'purchase';
    return emitGoogleEvent({
        locale,
        userData: user,
        gtmData: {
            event: GoogleTagsFE[eventName],
            eventId,
            ecommerce: {
                transaction_id: orderNumber,
                user_phone: user?.phone,
                user_email: user?.email,
                value: totalPriceOfItems?.withoutTax,
                tax: totalPrice?.tax,
                shipping: shipping?.totalPrice?.withoutTax,
                currency,
                ...(coupons?.length ? { coupon: coupons?.join(', ') } : {}),
                items: items?.map((it) => {
                    const { roundedQuantity, quantityBeforeRounding } = parseQuantities(it?.quantity);
                    return {
                        item_category: it?.categoryCodes?.join(', '),
                        item_id: it?.codeShort,
                        item_name: it?.name,
                        price: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                        quantityBeforeRounding,
                        ...(it?.coupons?.length
                            ? { coupon: it?.coupons?.join(', ') }
                            : {}),
                    };
                }) || [],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: items
                    ?.map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                currency,
                value: totalPriceOfItems?.withoutTax,
                contents: items?.map((it) => {
                    const { roundedQuantity } = parseQuantities(it?.quantity);
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: roundedQuantity,
                    };
                }),
            },
        },
    });
}
export async function emitViewItemGoogleEvent({ categoryCodes, codeShort, codeShortOfVariants, currency, customDataLayerData, locale, name, totalPrice, }) {
    const eventId = `viewContent_${nanoid()}`;
    const eventName = 'view_item';
    const contentIds = codeShortOfVariants?.length
        ? codeShortOfVariants
        : [codeShort].filter((it) => {
            return it;
        });
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                value: totalPrice,
                currency,
                contentIds,
                items: [
                    {
                        item_category: categoryCodes?.join(', '),
                        item_id: codeShort,
                        item_name: name,
                        price: totalPrice,
                        quantity: 1,
                        quantityBeforeRounding: 1,
                    },
                ],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds,
                currency,
                value: totalPrice,
                contents: [
                    {
                        category: categoryCodes?.join(', '),
                        productId: codeShort,
                        title: name,
                        itemPrice: totalPrice,
                        quantity: 1,
                    },
                ],
            },
        },
    });
}
export async function emitSelectItemGoogleEvent({ categoryCodes, codeShort, currency, customDataLayerData, locale, name, totalPrice, }) {
    const eventId = `selectItem_${nanoid()}`;
    const eventName = 'select_item';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                currency,
                // TODO: refactor - no value (but currency is here), and no contentIds?
                items: [
                    {
                        item_category: categoryCodes?.join(', '),
                        item_id: codeShort,
                        item_name: name,
                        price: totalPrice?.withoutTax,
                        quantity: 1,
                        quantityBeforeRounding: 1,
                    },
                ],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                contentIds: [codeShort].filter((it) => {
                    return it;
                }),
                currency,
                contents: [
                    {
                        category: categoryCodes?.join(', '),
                        productId: codeShort,
                        title: name,
                        itemPrice: totalPrice?.withoutTax,
                        quantity: 1,
                    },
                ],
            },
        },
    });
}
export async function emitViewItemListGoogleEvent({ currency, customDataLayerData, items, listId, locale, }) {
    if (!listId) {
        return;
    }
    const eventId = `viewContent_${nanoid()}`;
    const eventName = 'view_content';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            ecommerce: {
                // TODO: refactor - no value (but currency is here), and no contentIds?
                item_list_id: listId,
                currency,
                items: items?.map((item) => {
                    return {
                        item_category: item?.categoryCodes?.join(', '),
                        item_id: item?.codeShort,
                        item_name: item?.name,
                        price: item?.totalPrice?.withoutTax,
                        quantity: 1,
                        quantityBeforeRounding: 1,
                    };
                }) || [],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                currency,
                contentIds: items
                    ?.map((it) => {
                    return it?.codeShort;
                })
                    .filter((it) => {
                    return it;
                }),
                contents: items?.map((it) => {
                    return {
                        category: it?.categoryCodes?.join(', '),
                        productId: it?.codeShort,
                        title: it?.name,
                        itemPrice: it?.totalPrice?.withoutTax,
                        quantity: 1,
                    };
                }),
            },
        },
    });
}
export async function emitAddToWishListGoogleEvent({ codeShortOfVariants, currency, customDataLayerData, externalId, items, locale, totalPrice, wishListCode, }) {
    if (!items?.length) {
        return;
    }
    return Promise.all(items.map((item) => {
        const eventId = `wishlist_${externalId}_${wishListCode}_${item?.codeShort}`;
        const eventName = 'add_to_wishlist';
        const contentIds = codeShortOfVariants?.length
            ? codeShortOfVariants
            : items
                ?.map((it) => {
                return it?.codeShort;
            })
                .filter((it) => {
                return it;
            });
        return emitGoogleEvent({
            locale,
            gtmData: {
                event: GoogleTagsFE[eventName],
                eventId,
                ecommerce: {
                    value: totalPrice,
                    currency,
                    contentIds,
                    items: [
                        {
                            item_category: item?.categoryCodes?.join(', '),
                            item_id: item?.codeShort,
                            item_name: item?.name,
                            price: item?.totalPrice?.withoutTax,
                            quantity: 1,
                            quantityBeforeRounding: 1,
                        },
                    ],
                },
                ...(customDataLayerData ? { customDataLayerData } : {}),
            },
            apiData: {
                eventId,
                eventName: GoogleTagsBE[eventName],
                customData: {
                    contentIds,
                    value: totalPrice,
                    currency,
                    contents: items?.map((it) => {
                        return {
                            category: it?.categoryCodes?.join(', '),
                            productId: it?.codeShort,
                            title: it?.name,
                            itemPrice: item?.totalPrice?.withoutTax,
                            quantity: 1,
                        };
                    }),
                },
            },
        });
    }));
}
export async function emitLeadGoogleEvent({ currency, customDataLayerData, locale, login, webSystemId, }) {
    const eventId = `completeRegistration_${webSystemId}_${login}`;
    const eventName = 'lead';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            content: 'registration_activated',
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                currency,
                contents: [],
                contentIds: [],
            },
        },
    });
}
export async function emitViewPageGoogleEvent({ currency, customDataLayerData, domain, language, locale, pageTitle, pageType, }) {
    const eventId = `viewPage_${nanoid()}`;
    const eventName = 'fg_page_view';
    return emitGoogleEvent({
        locale,
        gtmData: {
            event: GoogleTagsFE[eventName],
            eventId,
            site: domain,
            language,
            type: pageType,
            title: pageTitle,
            currency,
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                currency,
                contentCategory: pageType,
                contentName: pageTitle,
                contents: [],
                contentIds: [],
            },
        },
    });
}
export async function emitSubmitFormGoogleEvent({ currency, customDataLayerData, mailHash, formId, formName, formText, formType, locale, webSystemId, }) {
    const eventId = `lead_${webSystemId}_${mailHash}`;
    const eventName = 'form';
    return emitGoogleEvent({
        locale,
        gtmData: {
            eventId,
            event: GoogleTagsFE[eventName],
            form: {
                id: formId,
                name: formName,
                type: formType,
                text: formText,
            },
            _clear: true,
            ...(customDataLayerData ? { customDataLayerData } : {}),
        },
        apiData: {
            eventId,
            eventName: GoogleTagsBE[eventName],
            customData: {
                currency,
                contents: [],
                contentIds: [],
            },
        },
    });
}

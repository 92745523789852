import { NEXT_ROUTER_PREFETCH_HEADER, NEXT_ROUTER_STATE_TREE_HEADER, RSC_HEADER, } from 'next/dist/client/components/app-router-headers';
export const JUAN_REQUEST_HEADER_HREF = 'juan-request-href';
export const JUAN_DRAFT_MODE_COOKIE = '__prerender_bypass';
export const JUAN_DRAFT_MODE_COOKIE_DETAIL = 'JUAN_PREVIEW';
export const ConsentCookie = 'fg-cookie-consent';
//je stejné jako FLIGHT_PARAMETER v app-router-headers.d.ts
export const FLIGHT_PARAMETERS_FOR_PROXY = [
    [RSC_HEADER],
    [NEXT_ROUTER_STATE_TREE_HEADER],
    [NEXT_ROUTER_PREFETCH_HEADER],
];
export const USER_COOKIE_NAME = 'user';
export const JUAN_INTERNAL_BASE = `http://127.0.0.1:${process.env.PORT || 3000}`;

'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useReportWebVitals } from 'next/web-vitals';
const WebVitalsBase = () => {
    useReportWebVitals((metric) => {
        const body = JSON.stringify(metric);
        const url = new URL('/api/analytics', window.location.origin).href;
        // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
        if (navigator.sendBeacon) {
            navigator.sendBeacon(url, body);
        }
        else {
            fetch(url, { body, method: 'POST', keepalive: true });
        }
    });
    return null;
};
export const WebVitals = () => {
    if (!CONFIG.analytics?.webVitals) {
        return null;
    }
    return _jsx(WebVitalsBase, {});
};

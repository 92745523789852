export default function getI18nStringFromLocaleDictionary(dictionary, key) {
    if (!dictionary) {
        return;
    }
    let finalValue = dictionary[key];
    if (!finalValue) {
        const keyPath = key.split('.');
        let selfeKey;
        if (keyPath.length > 1) {
            let i = 0;
            while (finalValue === undefined && i < keyPath.length - 1) {
                i++;
                const pathBag = keyPath.slice(i - keyPath.length);
                // if the path is only from one part, the resolution is broken and the path from one key is stored in a variable. This is used in the next step after the first and last parts of the path have been constructed.
                if (pathBag.length > 1) {
                    finalValue = dictionary[pathBag.join('.')];
                }
                else {
                    selfeKey = pathBag[0];
                }
            }
        }
        // If the key is not found by sequential truncation, the key is built from the first and last part of the path
        if (!finalValue && keyPath.length > 2) {
            finalValue =
                dictionary[[keyPath[0], keyPath[keyPath.length - 1]].join('.')];
        }
        // If the key is not found by sequential truncation, the key is built from the second and last part of the path
        if (!finalValue && keyPath.length > 3) {
            finalValue =
                dictionary[[keyPath[1], keyPath[keyPath.length - 1]].join('.')];
        }
        // If the key is not found by sequential truncation, the path from one part is used
        if (!finalValue && selfeKey) {
            finalValue = dictionary[selfeKey];
        }
    }
    return finalValue;
}

export const isNumberLike = function (id) {
    if (id == null) {
        return false;
    }
    if (typeof id === 'number') {
        return true;
    }
    const parsedNumber = typeof id === 'string' ? parseFloat(id) : id;
    return (!Number.isNaN(parsedNumber) && parsedNumber.toString() === id.toString());
};
export const isIterable = function (obj) {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
};
function createLabel(nameOrId) {
    throw 'unimplemented';
}
function parseSearchParams(searchParams, pickParamGroup, keepNumberLikeString, keepOneValueNotLikeArray) {
    let newSearchParams = {};
    if (searchParams == null) {
        return newSearchParams;
    }
    const obj = isIterable(searchParams)
        ? Object.fromEntries(searchParams)
        : searchParams;
    for (const [key, value] of Object.entries(obj)) {
        const splitValue = typeof value == 'string' ? value?.split(',') : value;
        const paramValues = splitValue.map((item) => {
            return !keepNumberLikeString && isNumberLike(item)
                ? parseFloat(item)
                : item;
        });
        newSearchParams[key] =
            paramValues.length === 1 && keepOneValueNotLikeArray
                ? paramValues[0]
                : paramValues;
    }
    // pick params starts with string from pickParamGroup array
    if (pickParamGroup) {
        const pickedSearchParams = {};
        const regexStartsWith = new RegExp(`^(${pickParamGroup.join('|')})`);
        // @ts-ignore
        Object.entries(newSearchParams).forEach(([key, value]) => {
            if (key.match(regexStartsWith)) {
                pickedSearchParams[key] = value;
            }
        });
        newSearchParams = pickedSearchParams;
    }
    return newSearchParams;
}
export { parseSearchParams };

'use client';
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useAfterPaintEffect } from '@edeeone/juan-core/utils/cwv';
import { default as NextLink } from 'next/link';
import { Children, cloneElement, useEffect } from 'react';
import { emitAddToCartGoogleEvent, emitLeadGoogleEvent, emitPurchaseGoogleEvent, emitSelectItemGoogleEvent, emitSubmitFormGoogleEvent, emitViewCartGoogleEvent, emitViewItemGoogleEvent, emitViewItemListGoogleEvent, emitViewPageGoogleEvent, } from './eventEmitter';
import { useDataLayer } from './JuanGoogleTagManager';
export function AddToCartGoogleEventWrapper(props) {
    const { locale } = useLocation();
    useEffect(() => {
        emitAddToCartGoogleEvent({ ...props, locale });
    }, []);
    return _jsx(_Fragment, {});
}
export function ViewCartGoogleEventWrapper(props) {
    const { locale } = useLocation();
    useEffect(() => {
        emitViewCartGoogleEvent({ ...props, locale });
    }, []);
    return _jsx(_Fragment, {});
}
export function PurchaseGoogleEventWrapper(props) {
    const { locale } = useLocation();
    useEffect(() => {
        emitPurchaseGoogleEvent({ ...props, locale });
    }, []);
    return _jsx(_Fragment, {});
}
export function SubmitFormGoogleEventWrapper(props) {
    const { locale, currency, webSystemId } = useLocation();
    useEffect(() => {
        emitSubmitFormGoogleEvent({ ...props, locale, currency, webSystemId });
    }, []);
    return _jsx(_Fragment, {});
}
export function ViewItemGoogleEventWrapper(props) {
    const { locale } = useLocation();
    useEffect(() => {
        emitViewItemGoogleEvent({ ...props, locale });
    }, []);
    return _jsx(_Fragment, {});
}
export function ViewItemListGoogleEventWrapper(props) {
    const { currency, locale } = useLocation();
    useEffect(() => {
        emitViewItemListGoogleEvent({
            ...props,
            currency,
            locale,
        });
    }, []);
    return _jsx(_Fragment, {});
}
export function LeadGoogleEventWrapper(props) {
    const { currency, locale } = useLocation();
    useEffect(() => {
        emitLeadGoogleEvent({
            ...props,
            currency,
            locale,
        });
    }, []);
    return _jsx(_Fragment, {});
}
export function ViewPageGoogleEventWrapper(props) {
    const { href, domain, locale, currency, type } = useLocation();
    const { initDataLayerIfNeeded } = useDataLayer();
    useAfterPaintEffect(() => {
        initDataLayerIfNeeded();
        emitViewPageGoogleEvent({
            ...props,
            currency,
            domain,
            language: locale,
            locale,
            pageTitle: document.head.querySelector('title')?.textContent || undefined,
            pageType: type,
        });
    }, [href], { priority: 'background' });
    return _jsx(_Fragment, {});
}
export function LinkWithOnClickSelectItemGoogleEvent({ categoryCodes, children, codeShort, name, productId, totalPrice, ...props }) {
    const { currency, locale } = useLocation();
    return (_jsx(NextLink, { ...props, onClick: () => {
            if (codeShort && productId) {
                emitSelectItemGoogleEvent({
                    categoryCodes,
                    codeShort,
                    currency,
                    locale,
                    name,
                    productId,
                    totalPrice: totalPrice,
                });
            }
        }, children: Children.map(children, (child) => {
            if (typeof child === 'string' ||
                typeof child === 'number' ||
                typeof child === 'boolean' ||
                !child) {
                return child;
            }
            return cloneElement(child, {
                ...(child?.props || {}),
            });
        }) }));
}

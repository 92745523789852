import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ModalButtonBack } from './ModalButtonBack';
import { modalClassNames } from './modal.styles';
export const dialogPanelId = 'Dialog.Panel';
export const Modal = ({ isOpen, closeLink, setIsOpen, title, footer, placement = 'right', design = 'primary', size = 'sm', children, styles, closeOnClickOutside = true, showBackBtn = false, }) => {
    const finalStyles = modalClassNames(styles, design, size);
    function handleClose() {
        // JTR
        // closeLink && window.location.replace(closeLink);
        isOpen && setIsOpen && setIsOpen(false);
    }
    useChangePathname({ callback: handleClose });
    const { t } = useI18n('Modal');
    return (_jsx(_Fragment, { children: _jsx(Transition, { appear: true, show: isOpen, as: Fragment, children: _jsxs(Dialog, { className: finalStyles.dialog, onClose: closeOnClickOutside ? handleClose : () => { }, open: isOpen, children: [_jsx(Transition.Child, { as: Fragment, enter: finalStyles.backgroundAnimation, enterFrom: finalStyles.backgroundFrom, enterTo: finalStyles.backgroundTo, leave: finalStyles.backgroundAnimation, leaveFrom: finalStyles.backgroundTo, leaveTo: finalStyles.backgroundFrom, children: _jsx("div", { className: finalStyles.background }) }), _jsx("div", { className: twMergeJuan(finalStyles.modalGrid, finalStyles[`placement__${placement}`]), children: _jsx(Transition.Child, { as: Fragment, enter: finalStyles.containerAnimation, enterFrom: finalStyles.containerFrom, enterTo: finalStyles.containerTo, leave: finalStyles.containerAnimation, leaveFrom: finalStyles.containerTo, leaveTo: finalStyles.containerFrom, afterEnter: () => {
                                // Nemám kde získat ref na dialog__panel, takže takto
                                const dialog = document.querySelector('.dialog__panel');
                                if (dialog != null) {
                                    dialog.scrollTop = 0;
                                }
                            }, children: _jsxs(Dialog.Panel, { id: dialogPanelId, className: twMergeJuan(finalStyles.container, finalStyles.container__custom, footer && finalStyles.container__footer, finalStyles[`container__${placement}`]), children: [setIsOpen && (_jsx("button", { onClick: handleClose, className: finalStyles.closeBtn, "aria-label": t('close'), children: _jsx(SvgSpriteIcon, { className: finalStyles.closeIcon, icon: "cross" }) })), showBackBtn && _jsx(ModalButtonBack, { label: t('goBack') }), _jsxs("div", { className: finalStyles.content, children: [title && (_jsx(Dialog.Title, { as: "h3", className: finalStyles.title, children: title })), children] }), footer && (_jsx(Transition.Child, { as: Fragment, enter: finalStyles.containerAnimation, enterFrom: finalStyles.containerFrom, enterTo: finalStyles.containerTo, leave: finalStyles.containerAnimation, leaveFrom: finalStyles.containerTo, leaveTo: finalStyles.containerFrom, children: _jsx("div", { className: finalStyles.footer, children: footer }) }))] }) }) })] }) }) }));
};

'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ApolloProvider } from '@apollo/client';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { CookiesProvider, getCookiesFromDocument } from '../Cookies';
import { GlobalStateProvider } from '../GlobalState';
import { useApollo } from '../graphql/apollo';
import { ServerActionModal } from '@edeeone/juan-core/components/serverActionModal/ServerActionModal';
if (typeof window !== 'undefined') {
    window.fetch = new Proxy(window.fetch, {
        apply(actualFetch, that, args) {
            const [resource, config] = args;
            const isPrefetch = config?.headers?.['Next-Router-Prefetch'] === '1';
            const isAction = !!config?.headers?.['Next-Action'];
            const newConfig = {
                ...config,
                headers: {
                    ...config?.headers,
                    // NOTE: Internally, next.js deletes the Next-Router-Prefetch and Next-Action headers and they are not available in the middleware. That's why we send our own headers here.
                    ...(isPrefetch ? { 'x-juan-prefetch': '1' } : {}),
                    ...(isAction ? { 'x-juan-action': '1' } : {}),
                },
            };
            // Forward function call to the original fetch
            const result = Reflect.apply(actualFetch, that, [resource, newConfig]);
            const beforeFetchCookies = getCookiesFromDocument();
            result.then((response) => {
                const afterFetchCookies = getCookiesFromDocument();
                if (afterFetchCookies.user_refresh &&
                    afterFetchCookies.user_refresh !== beforeFetchCookies.user_refresh) {
                    window.location.reload();
                    return;
                }
                // pokud se v nějakém fetchi provede redirect, tak se přesměrujeme. Děje se zejména pokud se uživatel odhlásí
                if (!isPrefetch &&
                    response?.redirected &&
                    // jen pokud se jedná o server action
                    response?.headers?.get('x-action-revalidated') &&
                    response?.url !== window.location.href) {
                    window.location.href = response.url;
                    return;
                }
                // pokud se změní user_in cookie, tak je třeba aktualizovat uživatele pouze pokud se nejedná o prefetch
                if (!isPrefetch &&
                    beforeFetchCookies.user_in !== afterFetchCookies.user_in) {
                    refreshTags(['userProfile']);
                }
                // TODO Refresh client side queries with tags. Až budou server action podporovat nastavení hlaviček
                /*
                const tags = response?.headers?.get('x-juan-refresh-tags');
                if (tags) {
                  console.log('tags', tags?.split(','));
                }
                */
            });
            return result;
        },
    });
}
export const LayoutRootProviderClient = ({ children, }) => {
    const apolloClientFromHook = useApollo(null);
    // useFetchProxy();
    return (_jsx(ApolloProvider, { client: apolloClientFromHook, children: _jsx(CookiesProvider, { children: _jsxs(GlobalStateProvider, { children: [_jsx(ServerActionModal, {}), children] }) }) }));
};

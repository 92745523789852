import { removeTrailingSlash } from 'next/dist/shared/lib/router/utils/remove-trailing-slash';
const defaultLocale = process.env.JUAN_DEFAULT_LOCALE;
export const REGION_COOKIE_NAME = CONFIG?.lang?.cookieName || 'region';
export const REGION_COOKIE_EXPIRE = CONFIG?.lang?.expirationTime || 1000 * 60 * 60 * 24 * 365;
export const WEB_ID_AND_REGION_SPLIT_CHAR = '~';
export const REGION_SWITCH_PATHNAME = '/api/region-switch';
export const getRegionLocationByLocale = (locale, country, currency) => {
    const regions = process.env
        .JUAN_REGIONS_LANGUAGES;
    const regionParsed = regions.find((region) => {
        return (region.lang === locale &&
            region.country === country &&
            region.currency === currency);
    });
    const defaultVal = regions.find((region) => {
        return region.lang === locale;
    }) || regions[0];
    return regionParsed || defaultVal;
};
export const getDefaultRegion = () => {
    const regions = process.env
        .JUAN_REGIONS_LANGUAGES;
    // first is default
    return regions[0];
};
// is for project without regions. that is if eshop is not configured and project is only simple website
export const getFallbackRegion = (locale = defaultLocale) => {
    return {
        lang: locale,
        currency: 'USD',
        country: 'CZ',
        region: `cz${locale}eur`,
        languageDuplicate: false,
    };
};
export const getRegionLanguageById = (regionId) => {
    const regions = process.env
        .JUAN_REGIONS_LANGUAGES;
    return regions.find((region) => {
        return region.region === regionId;
    });
};
export const getRegionAndAdjustWithLocale = (locale, regionId // czcseur, czcsczk, ...
) => {
    const regions = process.env
        .JUAN_REGIONS_LANGUAGES;
    let region;
    if (regionId) {
        region = getRegionLanguageById(regionId);
    }
    if (region && region.lang !== locale) {
        region = undefined;
    }
    if (!region) {
        // get default region for locale
        region =
            regions.find((region) => {
                return region.lang === locale;
            }) ||
                getDefaultRegion() ||
                getFallbackRegion(locale);
    }
    return region;
};
export const getAdjustedRegion = (regionId) => {
    const regions = process.env
        .JUAN_REGIONS_LANGUAGES;
    // zatím implementováno adjust podle country
    const countryFromRegion = regionId.slice(0, 2);
    const regionByCountry = regions.find((region) => {
        return region.country.toLowerCase() === countryFromRegion.toLowerCase();
    });
    return regionByCountry || getDefaultRegion();
};
export const isDefaultRegion = (regionId) => {
    const region = getDefaultRegion() || getFallbackRegion();
    return regionId === region.region;
};
export const getStartsUrlWithSomeRegion = (href) => {
    // http://localhost is fallback for href without origin
    const { pathname } = new URL(href, 'http://localhost');
    // TODO In unit tests is process.env.JUAN_REGIONS_LANGUAGES like string. In Next.js is object
    const envRegions = process.env.JUAN_REGIONS_LANGUAGES;
    const regions = typeof envRegions === 'string'
        ? JSON.parse(envRegions)
        : envRegions;
    const pathnameBag = pathname.split('/').slice(1);
    const firstPartBag = pathnameBag[0]?.split(WEB_ID_AND_REGION_SPLIT_CHAR);
    const regionPart = firstPartBag[1];
    const region = regions?.find((region) => {
        return regionPart === region.region;
    });
    if (!region) {
        return {};
    }
    return {
        region,
        webId: firstPartBag[0],
    };
};
const fallbackOrigin = 'http://___localhost';
export const removeRegionFromUrlIfStartsWithSome = (url) => {
    const { region, webId } = getStartsUrlWithSomeRegion(url);
    const urlObj = new URL(url, fallbackOrigin); // 'http://localhost' is a placeholder base for relative URLs
    if (region) {
        urlObj.pathname = urlObj.pathname.replace(`/${webId}${WEB_ID_AND_REGION_SPLIT_CHAR}${region.region}/`, '/');
        if (urlObj.pathname ===
            `/${webId}${WEB_ID_AND_REGION_SPLIT_CHAR}${region.region}`) {
            urlObj.pathname = '/';
        }
        if (!urlObj.pathname.startsWith('/')) {
            urlObj.pathname = `/${urlObj.pathname}`;
        }
    }
    return urlObj.href.replace(fallbackOrigin, '');
};
export const addRegionToUrl = (url, regionId, webId) => {
    const normalizedUrl = typeof url === 'string' ? new URL(url, 'https://fallback') : url;
    let pathname = normalizedUrl.pathname;
    pathname = removeRegionFromUrlIfStartsWithSome(pathname);
    const languageRegion = getRegionLanguageById(regionId) || getDefaultRegion();
    pathname = `/${webId}${WEB_ID_AND_REGION_SPLIT_CHAR}${languageRegion.region}${pathname}`;
    const finalUrl = new URL(removeTrailingSlash(`${pathname}${normalizedUrl.search}${normalizedUrl.hash}`), normalizedUrl.origin);
    return finalUrl;
};

'use client';
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useEffect } from 'react';
import extendSessionServerAction from '../extendSession.serverAction';
export function SessionExtender() {
    function handleVisibilityChange() {
        if (document.visibilityState === 'visible') {
            extendSessionServerAction(true).then(() => {
                // Refresh user profile after activate browser tab
                refreshTags(['userProfile']);
            });
        }
    }
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        const intervalTimerId = setInterval(() => {
            extendSessionServerAction();
        }, CONFIG.edee.remainingExpirationToExtendSessionInSeconds * 1000);
        return () => {
            clearInterval(intervalTimerId);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return _jsx(_Fragment, {});
}

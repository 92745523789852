export var GoogleTagsFE;
(function (GoogleTagsFE) {
    GoogleTagsFE["add_payment_info"] = "add_payment_info";
    GoogleTagsFE["add_shipping_info"] = "add_shipping_info";
    GoogleTagsFE["add_to_cart"] = "add_to_cart";
    GoogleTagsFE["add_to_wishlist"] = "add_to_wishlist";
    GoogleTagsFE["begin_checkout"] = "begin_checkout";
    GoogleTagsFE["fg_page_view"] = "fg_page_view";
    GoogleTagsFE["form"] = "form";
    GoogleTagsFE["lead"] = "lead";
    GoogleTagsFE["purchase"] = "purchase";
    GoogleTagsFE["remove_from_cart"] = "remove_from_cart";
    GoogleTagsFE["select_item"] = "select_item";
    GoogleTagsFE["view_cart"] = "view_cart";
    GoogleTagsFE["view_item"] = "view_item";
    GoogleTagsFE["view_content"] = "view_content";
})(GoogleTagsFE || (GoogleTagsFE = {}));
export var GoogleTagsBE;
(function (GoogleTagsBE) {
    GoogleTagsBE["add_payment_info"] = "AddPaymentInfo";
    // custom event
    GoogleTagsBE["add_shipping_info"] = "AddShippingInfo";
    GoogleTagsBE["add_to_cart"] = "AddToCart";
    GoogleTagsBE["add_to_wishlist"] = "AddToWishlist";
    GoogleTagsBE["begin_checkout"] = "InitiateCheckout";
    // custom event
    GoogleTagsBE["fg_page_view"] = "FgPageView";
    GoogleTagsBE["form"] = "Lead";
    GoogleTagsBE["lead"] = "CompleteRegistration";
    GoogleTagsBE["purchase"] = "Purchase";
    // custom event
    GoogleTagsBE["remove_from_cart"] = "RemoveFromCart";
    // custom event
    GoogleTagsBE["select_item"] = "SelectItem";
    // custom event
    GoogleTagsBE["view_cart"] = "ViewCart";
    GoogleTagsBE["view_item"] = "ViewContent";
    // custom event
    GoogleTagsBE["view_content"] = "ViewContent";
})(GoogleTagsBE || (GoogleTagsBE = {}));
export async function encodeToSHA256(input) {
    if (!input || !crypto.subtle) {
        return;
    }
    const encoder = new TextEncoder();
    const bufferData = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', bufferData);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => {
        return b.toString(16).padStart(2, '0');
    })
        .join('');
    return hashHex;
}
export function parseQuantities(quantity) {
    const quantityAsNumber = typeof quantity === 'number'
        ? quantity
        : isNaN(Number(quantity))
            ? undefined
            : Number(quantity);
    return {
        roundedQuantity: quantityAsNumber === undefined ? undefined : Math.round(quantityAsNumber),
        quantityBeforeRounding: quantityAsNumber,
    };
}
export const CLICK_ID_COOKIE_NAME = '_fbc';
export const FBP_COOKIE_NAME = '_fbp';
export const FALLBACK_EXTERNAL_ID_COOKIE_NAME = 'externalID';

import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const serverActionModalDefaultClassNames = /* tw */ {
    skeleton_wrapper: 'relative -mt-[4.25rem] pb-16',
    skeleton_title: 'w-1/2 mx-auto py-6 px-8 xs:p-10 xsm:p-12',
    skeleton_title_bar: 'h-10',
    skeleton_content: 'px-6',
};
export function serverActionModalClassNames(...args) {
    return getMemoizedFinalClassnames('serverActionModalClassNames', serverActionModalDefaultClassNames, ...args);
}

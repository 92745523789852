'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useRouter } from 'next/navigation';
import { modalClassNames } from './modal.styles';
export const ModalButtonBack = ({ label }) => {
    const finalStyles = modalClassNames();
    const router = useRouter();
    return (_jsx(Button, { design: "quaternary", icon: 'arrowLeft', styles: { button__custom: finalStyles.backBtn }, onClick: () => {
            return router.back();
        }, children: label }));
};

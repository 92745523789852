export function getCurrencySymbol(locale, currency) {
    // If not shop project, return empty string
    if (typeof currency === 'undefined') {
        return '';
    }
    return (0)
        .toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
        .replace(/\d/g, '')
        .trim();
}

import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const skeletonDefaultClassNames = /* tw */ {
    skeleton: 'skeleton animate-pulse flex flex-col gap-2',
    skeleton__custom: '',
    bar: 'skeleton-bar relative block w-full bg-gray-20 h-2.5',
    bar__custom: '',
};
export function skeletonClassNames(...args) {
    return getMemoizedFinalClassnames('skeletonClassNames', skeletonDefaultClassNames, ...args);
}

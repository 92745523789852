'use client';
import { getActiveWeb } from '@edeeone/juan-core/routes/utils/getActiveWeb';
import { getBase } from '@edeeone/juan-core/routes/utils/getBase';
import { getLocales } from '@edeeone/juan-core/routes/utils/getLocales';
import { addRegionToUrl, getRegionAndAdjustWithLocale, getStartsUrlWithSomeRegion, } from '@edeeone/juan-core/routes/utils/region';
import { getCurrencySymbol } from '@edeeone/juan-core/utils/getCurrencySymbol';
import { parseSearchParams } from '@edeeone/string/parseSearchParams';
const regions = process.env.JUAN_REGIONS_LANGUAGES;
// funkce bez volání normalizeJuanUrl. To by se mělo volat jen na serveru. Funkce je pouze pro client side. Na serveru použijte parseLocationWithoutRouteFromHref
export const parseLocationWithoutRouteFromHrefWithoutNormalization = function (href, cookies) {
    // defaultHref is used for error pages and at buildtime
    const defaultHref = getBase().href;
    const originalHref = href || defaultHref;
    const regionAndWebId = getStartsUrlWithSomeRegion(originalHref);
    if (!regionAndWebId) {
        throw new Error('Region is not defined in url');
    }
    let region = regionAndWebId.region;
    const hrefUrl = new URL(originalHref); //normalizeJuanUrl(originalHref);
    const pathname = hrefUrl.pathname;
    const locales = getLocales();
    const activeWeb = getActiveWeb(regionAndWebId.webId);
    if (!region) {
        region = getRegionAndAdjustWithLocale(activeWeb.locale, 
        // Pokud není region v url, tak se snažíme zparsovat z cookiespokud to situace dovoluje
        // POZOR na předávání cokies ve staticky generovaných stránkách
        cookies?.get('region')?.value);
    }
    const finalLocale = region.lang;
    // ___SLASH_ONLY___ se přidává v packages/juan-core/src/routes/routeResolver.ts pokjud je url rovna pouze lomítku '/'. Tímto se ošetřuje aby šablona např. homepage mohla být na cestě [...slug]/page.tsx
    // TODO ___SLASH_ONLY___ by asi použito být nemuselo - prověřit
    const finalHref = hrefUrl.href.replace('___SLASH_ONLY___', '');
    const query = parseSearchParams(hrefUrl.searchParams, undefined, true, true);
    let staticSearchParameter;
    if (CONFIG.evita &&
        CONFIG.evita.staticSearchParameter &&
        !!query[CONFIG.evita.staticSearchParameter]) {
        staticSearchParameter = query[CONFIG.evita.staticSearchParameter];
    }
    return {
        host: hrefUrl.host,
        protocol: hrefUrl.protocol.replace(':', ''),
        domain: hrefUrl.origin,
        origin: hrefUrl.origin,
        locale: finalLocale,
        prefix: activeWeb?.prefix,
        pathname: `${pathname.replace('___SLASH_ONLY___', '')}${staticSearchParameter
            ? `?${CONFIG.evita.staticSearchParameter}=${staticSearchParameter}`
            : ''}`,
        // without origin
        url: finalHref?.replace(hrefUrl.origin, ''),
        // with origin
        href: finalHref,
        hrefWithRegion: addRegionToUrl(finalHref, region.region, activeWeb.webId).toString(),
        country: region?.country,
        currency: region.currency,
        region: region?.region,
        regions,
        catalog: process.env.JUAN_CATALOG,
        currencySymbol: getCurrencySymbol(finalLocale, region.currency),
        webId: activeWeb.webId,
        webSystemId: activeWeb.webSystemId,
        locales,
        originalHref,
    };
};

import { I18nContext } from '@edeeone/juan-core/i18n/I18nContext';
import I18nDictionaryProviderClientContext from '@edeeone/juan-core/i18n/i18nProvider/I18nDictionaryProviderClient.context';
import getI18nStringFromLocaleDictionary from '@edeeone/juan-core/i18n/lib/getI18nStringFromLocaleDictionary';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getDefaultWeb } from '@edeeone/juan-core/routes/utils/getDefaultWeb';
import { processNbsp } from '@edeeone/string/processNbsp';
import { IntlMessageFormat } from 'intl-messageformat';
import { useContext } from 'react';
const dev = true; // process.env.NODE_ENV !== 'production';
export const useI18n = (namespace, locale) => {
    const { locale: routeLocale, type } = useLocation();
    const finalLocale = locale || routeLocale || getDefaultWeb().locale;
    const i18nKeyFromContext = useContext(I18nContext);
    const { dictionary } = useContext(I18nDictionaryProviderClientContext);
    const calculatedNamespace = `${i18nKeyFromContext ? i18nKeyFromContext + '.' : ''}${namespace}`;
    const finalNamespace = `${namespace || 'main'}`;
    return {
        tFormat: function (str, values = {}) {
            if (!str) {
                return '';
            }
            return new IntlMessageFormat(str, finalLocale).format(values);
        },
        t: function t(key, values = {}) {
            if (key == null) {
                return "Key isn't defined";
            }
            let finalKey = key.replace(`${finalNamespace}.`, '');
            finalKey = `${type}.${finalNamespace}.${finalKey}`;
            let str = getI18nStringFromLocaleDictionary(dictionary, finalKey) ||
                (dev ? `???${finalKey}???` : '');
            str = new IntlMessageFormat(processNbsp(str) || '', finalLocale).format(values);
            return str;
        },
        i18nKey: calculatedNamespace,
    };
};

'use client';
import { ConsentCookie } from '@edeeone/juan-core/constants';
import { useCookie } from '@edeeone/juan-core/Cookies';
import { useLocation } from '../routes/useLocation';
import { getEventsConfig, gtag } from '../utils/analyticsEventsUtils';
export function getConsentConfig(locale) {
    const analyticsEventsConfig = getEventsConfig(locale);
    return Object.assign({
        ad_personalization: 'MARKETING',
        ad_storage: 'MARKETING',
        ad_user_data: 'MARKETING',
        analytics_storage: 'ANALYTICS',
        functionality_storage: 'TECHNICAL',
        personalization_storage: 'TECHNICAL',
        security_storage: 'TECHNICAL',
    }, analyticsEventsConfig?.additionalConsentTypes || {});
}
export const DENIED_CONSENT_VALUE = 'denied';
export const GRANTED_CONSENT_VALUE = 'granted';
export const useConsent = () => {
    const { cookie, setCookie } = useCookie(ConsentCookie, []);
    const { locale } = useLocation();
    return {
        isGranted: (type) => {
            const ck = cookie;
            return ck?.includes(type);
        },
        consent: cookie,
        updateConsent: (type, consent = true, replace = false) => {
            let nextCk = [...(replace ? [] : cookie), ...type];
            if (!consent) {
                nextCk = cookie.filter((t) => {
                    return !type?.includes(t);
                });
            }
            updateConsents(type, locale);
            setCookie(nextCk?.filter((k, i, a) => {
                return a?.indexOf(k) == i;
            }), { expires: 60 * 60 * 24 * 365 });
        },
    };
};
export function updateConsents(cookieConsents, locale) {
    const consentsResolved = {};
    const analyticsEventsConfig = getEventsConfig(locale);
    const consentConfig = getConsentConfig(locale);
    Object.keys(consentConfig).forEach((consentName) => {
        consentsResolved[consentName] = cookieConsents.includes(consentConfig[consentName])
            ? GRANTED_CONSENT_VALUE
            : DENIED_CONSENT_VALUE;
    });
    if (analyticsEventsConfig?.logEventsWhenEmitting) {
        console.info('Update consents:', consentsResolved);
    }
    gtag('consent', 'update', consentsResolved);
    window.dataLayer?.push({
        event: '"consent_update"',
        consent: consentsResolved,
    });
    return consentsResolved;
}

'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useCookie } from '@edeeone/juan-core/Cookies';
import { ConsentCookie } from '@edeeone/juan-core/constants';
import { DENIED_CONSENT_VALUE, getConsentConfig, updateConsents, } from '@edeeone/juan-core/hooks/useConsent';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getEventsConfig, gtag, } from '@edeeone/juan-core/utils/analyticsEventsUtils';
import { useAfterPaintEffect } from '@edeeone/juan-core/utils/cwv';
import { GoogleTagManager } from '@next/third-parties/google';
function getDefaultConsents(locale) {
    const consentsResolved = {};
    const consentConfig = getConsentConfig(locale);
    Object.keys(consentConfig).forEach((consentName) => {
        consentsResolved[consentName] = DENIED_CONSENT_VALUE;
    });
    return consentsResolved;
}
export function useDataLayer() {
    const { locale } = useLocation();
    const { cookie: cookieConsents } = useCookie(ConsentCookie, []);
    function initDataLayerIfNeeded() {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        if (!window.dataLayer.some((event) => {
            return event?.event === 'consent_init';
        })) {
            const defaultConsents = getDefaultConsents(locale);
            const analyticsEventsConfig = getEventsConfig(locale);
            if (analyticsEventsConfig?.logEventsWhenEmitting) {
                console.info('Init dataLayer. Default consents:', defaultConsents);
            }
            gtag('consent', 'default', defaultConsents);
            window.dataLayer.push({
                event: 'consent_init',
                consent: defaultConsents,
                _clear: true,
            });
            if (cookieConsents?.length) {
                updateConsents(cookieConsents, locale);
            }
        }
    }
    return { initDataLayerIfNeeded };
}
function JuanGoogleTagManageBase() {
    const { locale } = useLocation();
    const analyticsEventsConfig = getEventsConfig(locale);
    const { initDataLayerIfNeeded } = useDataLayer();
    useAfterPaintEffect(initDataLayerIfNeeded, [], { priority: 'background' });
    if (analyticsEventsConfig?.gtmId) {
        return _jsx(GoogleTagManager, { gtmId: analyticsEventsConfig.gtmId });
    }
}
export function JuanGoogleTagManager() {
    const { locale } = useLocation();
    const analyticsEventsConfig = getEventsConfig(locale);
    if (!analyticsEventsConfig?.gtmId) {
        return null;
    }
    return _jsx(JuanGoogleTagManageBase, {});
}

import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const modalDefaultClassNames = /* tw */ {
    dialog: 'dialog relative z-50 print:hidden',
    background: 'dialog__overlay overlay-common z-10',
    backgroundAnimation: 'ease-out duration-300',
    backgroundFrom: 'opacity-0',
    backgroundTo: 'opacity-100',
    closeBtn: 'dialog__close-btn radius-common self-end p-6 sticky outline-none w-[4.6rem] top-6 right-6 bg-white z-30 bg-opacity-80 group/close',
    backBtn: 'dialog__back-btn !justify-start ml-6 xsm:ml-20',
    closeIcon: 'dialog__close-ico ico w-7 h-7 fill-gray-60 group-hover/close:fill-black',
    modalGrid: 'dialog__grid fixed inset-0 flex min-h-full items-end xsm:items-start z-20',
    container: 'dialog__panel overflow-hidden bg-white text-left align-middle shadow-xl transition-all relative flex flex-col max-h-[calc(100dvh_-_4rem)] xsm:max-h-screen !overflow-y-auto custom-scrollbar w-full xsm:w-auto',
    container__custom: '',
    container__footer: '',
    containerAnimation: 'ease duration-150 xsm:duration-300',
    containerFrom: 'opacity-0 h-0 md:h-auto xsm:w-0',
    containerTo: 'opacity-100',
    content: 'dialog__content',
    title: 'dialog__title text-base font-semibold text-black leading-normal mb-12',
    footer: 'dialog_footer',
    placement__left: ' xsm:h-screen justify-start',
    placement__center: 'justify-center items-center',
    placement__right: ' xsm:h-screen justify-end',
    container__left: 'xsm:h-screen',
    container__center: '',
    container__right: 'xsm:h-screen',
    // modál registrace, přihlášení, hodnocení
    primary: {
        content: 'pt-4 pb-20 px-6 xsm:px-20',
    },
    // modál filtrace, výběr odběrného místa
    secondary: {
        content: '',
        container: '',
        modalGrid: '!top-[4rem] !min-h-0 xsm:!top-0',
        closeBtn: 'top-4 -mb-[4.8rem]', // posunuti o vysku sama sebe nahoru
        title: 'px-12 xsm:px-[6.5rem] py-8 border-b border-b-gray-20 mb-0',
        footer: 'bg-gray-10 px-6 xsm:px-20 py-8 flex gap-6 justify-between fixed right-0 bottom-0 min-h-[9rem] transition-none',
        container__footer: 'mb-[9rem] !max-h-[calc(100%_-_9rem)] grow-0',
    },
    // modál fulltext našeptávače
    tertiary: {
        containerFrom: 'h-auto w-0',
        container: 'bg-none bg-opacity-0 max-h-[100dvh]',
        modalGrid: '!items-start',
        placement__left: 'h-screen',
        placement__right: 'h-screen',
        container__left: 'h-screen',
        container__right: 'h-screen',
    },
    // modál pro nastavení cookie
    quaternary: {
        closeBtn: 'top-4 -mb-[4.8rem]',
        title: 'px-12 sm:px-[7.5rem] py-8 sm:pt-24 border-b border-b-gray-20 sm:border-0 mb-0 text-lg leading-wide',
    },
    // modál přidání do košíku
    quinary: {
        content: 'pt-4 pb-20 px-6 xsm:px-20 !w-full sm:!w-[600px]',
    },
    // velikost SM
    sm: {
        containerTo: 'w-full xsm:w-[480px]',
        content: 'w-full xsm:w-[480px]',
        footer: 'w-full xsm:w-[480px]',
    },
    // velikost MD
    md: {
        containerTo: 'w-[600px]',
        content: 'md:w-[600px]',
        footer: 'w-[600px]',
    },
    // velikost LG
    lg: {
        containerTo: 'w-[720px]',
        content: 'md:w-[720px]',
        footer: 'w-[720px]',
    },
    // velikost XL
    xl: {
        containerTo: 'w-full md:w-[480px] lg:w-[986px]',
        content: 'w-full md:w-[480px] lg:w-[986px]',
        footer: 'w-full md:w-[480px] lg:w-[986px]',
    },
    // velikost 2XL
    '2xl': {
        containerTo: 'w-full md:w-[930px] lg:w-[986px] xl:w-[calc(100vw_/_3_*_2.5)]',
        content: 'w-full md:w-[930px] lg:w-[986px] xl:w-[calc(100vw_/_3_*_2.5)]',
        footer: 'w-full md:w-[930px]  lg:w-[986px] xl:w-[calc(100vw_/_3_*_2.5)]',
    },
};
export function modalClassNames(...args) {
    return getMemoizedFinalClassnames('modalClassNames', modalDefaultClassNames, ...args);
}

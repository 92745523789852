import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getBase } from '@edeeone/juan-core/routes/utils/getBase';
import process from 'process';
import { useMemo } from 'react';
if (process.env.NODE_ENV === 'development') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}
let apolloClient;
const isSsr = typeof window === 'undefined';
function createIsomorphLink(context = {}, schema) {
    // @ts-ignore
    let ssrHeaders = {};
    if (isSsr) {
        // TODO jde obejít :-/
        ssrHeaders = {
            'x-preview-token': context?.previewData?.token,
            'x-forwarded-proto': 'https',
            Host: getBase().host,
        };
    }
    if (!isSsr) {
        console.info('apply unhandledrejection listener');
        window.addEventListener('unhandledrejection', (event) => {
            if (event.reason.name === 'AbortError') {
                // console.warn('Request was aborted: ', event.reason);
                event.preventDefault(); // Zabrání zobrazení chyby v konzoli
            }
        });
    }
    const path = '/api/graphql';
    const localhostBypass = CONFIG.graphql.localhostBypass == null
        ? true
        : CONFIG.graphql.localhostBypass;
    let uri = localhostBypass
        ? `http://127.0.0.1:${process.env.PORT || 3000}${path}`
        : `${getBase().origin}${path}`;
    if (!isSsr) {
        uri = `${window.location.origin}${path}`;
    }
    const batchHttpLink = new BatchHttpLink({
        uri,
        batchMax: 10,
        credentials: 'same-origin',
        headers: {
            'x-enviroment': isSsr ? 'server' : 'client',
            ...ssrHeaders,
        },
    });
    return batchHttpLink;
    // }
}
function createApolloClient(context, schema) {
    return new ApolloClient({
        devtools: {
            enabled: !isSsr && CONFIG.graphql.connectToDevTools,
        },
        ssrMode: false, //isSsr,
        defaultOptions: {
            query: {
                errorPolicy: 'all',
            },
        },
        link: createIsomorphLink(context, schema),
        cache: new InMemoryCache({}),
    });
}
export function initializeApollo(initialState = null, 
// Pages with Next.js data fetching methods, like `getStaticProps`, can send
// a custom context which will be used by `SchemaLink` to server render pages
context, schema) {
    const _apolloClient = apolloClient ?? createApolloClient(context, schema);
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined')
        return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient)
        apolloClient = _apolloClient;
    return _apolloClient;
}
export function useApollo(initialState) {
    const store = useMemo(() => {
        return initializeApollo(initialState);
    }, [initialState]);
    return store;
}
export const refreshQueriesWithTag = async (tag) => {
    const queries = apolloClient?.getObservableQueries();
    // maybe we cant refetch queries that are not active
    // Clear data of inactive queries
    // Zkontroluj, jestli nějaké dotazy stále nejsou ve stavu načítání
    // https://github.com/apollographql/apollo-client/blob/main/src/core/networkStatus.ts#L40
    const hasInFlightQueries = Array.from(queries?.values() || []).some((q) => {
        return q.getCurrentResult().networkStatus !== 7;
    });
    // Pokud nejsou žádné dotazy ve stavu načítání, tak můžeš vyčist store. Pokud by tomu tak nebylo tak clearStore generuje chybu Invariant Violation: Store reset while query was in flight (not completed in link chain
    if (!hasInFlightQueries) {
        await apolloClient?.clearStore();
    }
    // Clear store až po dokončení všech dotazů
    const tagsArray = Array.isArray(tag) ? tag : [tag];
    return Promise.all(Array.from(queries?.entries() ?? []).map(([_name, q]) => {
        /*      console.log(
          'refetch',
          q.queryName,
          q.queryId,
          q.variables,
          q.options?.context
        );*/
        if (q.options?.context?.tags?.some((t) => {
            return tagsArray.includes(t);
        })) {
            return q.refetch();
        }
    }) ?? []);
};
export const refreshGraphql = async () => {
    return apolloClient?.cache.reset();
};

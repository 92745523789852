//'use server';
//import 'server-only';
// import { headers } from 'next/headers';
/**
 * Refresh client side queries with tags.
 * In future this will be move to the server side. TO server actions and graphql mutations
 * TODO v budoucnu až server actions budou podporovat nastavení response hlaviček
 */
/*
export const refreshTags = (tags: string[]) => {
  // console.log('requestStore', requestStore);
  const actualTags =
    headers().get('x-juan-refresh-tags')?.split(',') || ([] as string[]);
  const newTags = [...new Set([...actualTags, ...tags])];
  // v server action nejde setovat headers :-/
  // headers().set('x-juan-refresh-tags', newTags.join(','));
};
*/
import { refreshQueriesWithTag } from '@edeeone/juan-core/graphql/apollo';
/**
 * Refresh client side queries with tags.
 */
export const refreshTags = async (tags) => {
    return refreshQueriesWithTag(tags);
};

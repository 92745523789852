import { getEventsConfig, gtag, } from '@edeeone/juan-core/utils/analyticsEventsUtils';
import { encodeToSHA256 } from './utils';
export async function updateUserDataInDataLayer(locale, userData) {
    const analyticsEventsConfig = getEventsConfig(locale);
    if (!analyticsEventsConfig || !userData || !Object.keys(userData)?.length) {
        return;
    }
    const [encodedEmail, encodedFirstName, encodedLastName, encodedPhone] = await Promise.all([
        userData.email,
        userData.firstName,
        userData.lastName,
        userData.phone?.replaceAll(' ', ''),
    ].map((field) => {
        return encodeToSHA256(field);
    }));
    const address = {
        ...(userData.countryCode ? { country: userData.countryCode } : {}),
        ...(encodedFirstName ? { sha256_first_name: encodedFirstName } : {}),
        ...(encodedLastName ? { sha256_last_name: encodedLastName } : {}),
        ...(userData.postCode ? { postal_code: userData.postCode } : {}),
    };
    const gtagData = {
        ...(Object.keys(address)?.length ? { address } : {}),
        ...(encodedEmail ? { sha256_email_address: encodedEmail } : {}),
        ...(encodedPhone ? { sha256_phone_number: encodedPhone } : {}),
    };
    if (analyticsEventsConfig?.logEventsWhenEmitting) {
        console.info('Setting user data to data layer:', gtagData);
    }
    gtag('set', 'user_data', gtagData);
}

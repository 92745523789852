'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { ConsentCookie } from '@edeeone/juan-core/constants';
import { createContext, useContext, useState } from 'react';
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
const isBrowser = typeof window !== 'undefined';
export const getCookiesFromDocument = function () {
    if (!isBrowser)
        return {};
    let cookies = {};
    try {
        cookies = document.cookie
            .split('; ')
            .reduce((prev, current) => {
            const [name, ...value] = current.split('=');
            let normalizedValue = value;
            try {
                normalizedValue = value.map((v) => {
                    return JSON.parse(decodeURIComponent(v));
                });
            }
            catch {
                //
            }
            prev[name] = normalizedValue[0];
            return prev;
        }, {});
    }
    catch {
        //
    }
    return cookies;
};
export const setCookie = (name, value, options) => {
    if (!isBrowser)
        return;
    const optionsWithDefaults = {
        expires: 60 * 60 * 24 * 7, // 7days
        path: '/',
        domain: undefined,
        ...options,
    };
    const expires = new Date(Date.now() + optionsWithDefaults.expires * 1000).toUTCString();
    const domainCookieString = optionsWithDefaults.domain
        ? `;domain=${optionsWithDefaults.domain}`
        : '';
    document.cookie = `${name}=${encodeURIComponent(JSON.stringify(value))}${domainCookieString}; expires=${expires}; path=${optionsWithDefaults.path}`;
};
const isSSR = typeof window === 'undefined';
export const CookieContext = createContext({
    cookies: {},
    setCookie: (_key, _value, _options) => {
        return;
    },
    refresh: () => { },
});
export const CookiesProvider = function ({ children, cookies, }) {
    const [cookiesState, setCookieState] = useState(function () {
        return cookies || isSSR
            ? { [ConsentCookie]: JSON.stringify(['INIT']) }
            : getCookiesFromDocument();
    });
    const setCookieAndState = function (key, value, options) {
        setCookie(key, value, options);
        setCookieState({
            ...cookiesState,
            [key]: value,
        });
    };
    const refresh = function () {
        const cookies = getCookiesFromDocument();
        setCookieState(cookies);
    };
    // refresh cookies on every pathname change
    useChangePathname({
        callback: () => {
            refresh();
        },
        includeSearchParams: true,
    });
    return (_jsx(CookieContext.Provider, { value: {
            cookies: cookiesState,
            setCookie: setCookieAndState,
            refresh,
        }, children: children }));
};
export const useCookie = (key, defaultValue) => {
    const { setCookie, cookies } = useContext(CookieContext);
    return {
        cookie: cookies[key] || defaultValue,
        setCookie: (value, options) => {
            setCookie(key, value, options);
        },
    };
};
export const useCookiesRefresh = function () {
    const { refresh } = useContext(CookieContext);
    return refresh;
};
